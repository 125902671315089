import { PopupMessages } from 'Enums';
import PropTypes from 'prop-types';

function PopupScreen(props) {
	//------------------------------------------------------
	let viewData;

	switch (props.popupMessage) {
		case PopupMessages.LEVEL_COMPLETED:
			viewData = {
				header: 'Level Completed',
				messageTitle: 'Congratulation!',
				messageBody: 'Click continue to start next level.',
				buttonText: 'Continue',
			};
			break;
		case PopupMessages.PROGRESS_CLEARED:
			viewData = {
				header: 'App Upgrade',
				messageTitle: 'We need to clear your progress!',
				messageBody:
					'Unfortunatelly we made some changes to the app to make your experience better. Because of that we need to clear all progress.',
				buttonText: 'Ok',
			};
			break;
		case PopupMessages.INSTALL_ICON:
			viewData = {
				header: 'Homescreen Icon',
				icon: 'logo512.png',
				messageTitle: 'Make your experience better!',
				messageBody: (
					<>
						Add <b>Mr. Speak App Icon</b> to your homescreen. That
						way our app will always be one click away.
					</>
				),
				buttonText: 'Yes, please!',
				cancelText: 'No',
			};
			break;
		default:
			viewData = {
				header: 'Error',
				messageTitle: 'Unknown message!',
				buttonText: 'Close',
			};
			break;
	}

	//------------------------------------------------------
	return (
		<div className="backdrop-brightness-[30%] absolute min-h-screen min-w-full backdrop-blur-md flex flex-col justify-center items-center">
			<div className="border-black drop-shadow-xl w-[80%] max-w-[66vh] rounded-3xl flex flex-col justify-start items-center bg-gray-700">
				<div className="w-full border-b py-5 border-gray-900 bg-gray-800 flex flex-col justify-center items-center rounded-t-3xl text-2xl text-gray-300 font-bold uppercase">
					{viewData.header}
				</div>
				<div className="m-10 flex flex-col justify-center items-center w-[70%] text-md text-gray-300">
					{viewData.icon !== undefined && (
						<img className="w-[10vh] mb-10" src={viewData.icon} />
					)}

					{viewData.messageTitle !== undefined && (
						<div className="text-xl mb-5">
							{viewData.messageTitle}
						</div>
					)}

					{viewData.messageBody !== undefined && (
						<div className="text-center">
							{viewData.messageBody}
						</div>
					)}
				</div>

				<div className="mt-3 mb-10 flex flex-col justify-center items-center">
					<button
						onClick={props.onOkBtnClicked}
						className="flex justify-center items-center bg-gray-300 text-gray-700 mx-auto px-6 py-3 rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-xl uppercase"
					>
						{viewData.buttonText}
					</button>
				</div>

				{viewData.cancelText !== undefined && (
					<div className="mt-3 mb-10 flex flex-col justify-center items-center">
						<button
							onClick={props.onCancelBtnClicked}
							className="flex justify-center items-center bg-red-300 text-gray-700 mx-auto px-6 py-3 rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-xl uppercase"
						>
							{viewData.cancelText}
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

PopupScreen.propTypes = {
	popupMessage: PropTypes.number,
	onOkBtnClicked: PropTypes.func,
	onCancelBtnClicked: PropTypes.func,
};

export default PopupScreen;
