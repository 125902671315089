import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'utils/reportWebVitals';
import App from 'App';
import 'css/index.css';
import serviceWorkerRegistration from 'utils/serviceWorkerRegistration';
import adjustScreenSize from 'utils/adjustScreenSize';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

adjustScreenSize();
serviceWorkerRegistration();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
