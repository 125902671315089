import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
function Header(props) {
	return (
		<header className="bg-gray-600/50 rounded-t-3xl flex justify-between items-center grow-[1] basis-0">
			<div className="grow-[1] basis-0 flex justify-center">
				<button className="bg-gray-300 text-gray-700 mx-auto w-[8vh] h-[8vh] rounded-full ring-2 ring-gray-900/5 shadow-md font-bold text-3xl">
					{props.isLevelFinished === false && (
						<div className="drop-shadow-lg shadow-black">
							{props.currentLevel}
						</div>
					)}

					{props.isLevelFinished && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-[8vh] h-[8vh] text-green-700"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
						</svg>
					)}
				</button>
			</div>
			<div className="grow-[3] basis-0 flex justify-center">
				<img src="logo.png" className="h-[9vh]" alt="" />
			</div>
			<div className="grow-[1] basis-0 flex justify-center">
				<button
					type="button"
					className="h-[6vh] text-gray-300"
					onClick={props.onSearchBtnClicked}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="w-[6vh] h-[6vh] drop-shadow"
						fill="currentColor"
						viewBox="0 0 16 16"
					>
						<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
					</svg>
				</button>
			</div>
		</header>
	);
}

Header.propTypes = {
	currentLevel: PropTypes.number,
	isLevelFinished: PropTypes.bool,
	onSearchBtnClicked: PropTypes.func,
};

export default Header;
