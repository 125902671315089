import PropTypes from 'prop-types';
import Controls from 'components/Controls';
import Header from 'components/Header';
import Suggestion from 'components/Suggestion';
import Word from 'components/Word';
import Assessment from 'components/Assessment';
import LevelProgress from 'components/LevelProgress';

function MainScreen(props) {
	return (
		<div className="border-black min-h-full bg-gray-700 flex flex-col w-[66vh] m-3 rounded-3xl drop-shadow-xl">
			<Header
				currentLevel={props.currentLevel}
				isLevelFinished={props.isLevelFinished}
				onSearchBtnClicked={props.onSearchBtnClicked}
			/>
			<main className="border-gray-700 w-full border-t shadow-inner-t flex flex-col items-center justify-between grow-[6] basis-0">
				<LevelProgress
					totalSteps={props.totalLevelSteps}
					currentStep={props.currentLevelStep}
					completedSteps={props.completedLevelSteps}
					skippedWords={props.skippedWords}
				/>
				<Suggestion
					wordPhonemes={props.wordPhonemes}
					userPhonemes={props.userPhonemes}
					errorCode={props.errorCode}
					isRecording={props.isRecording}
					isAssessing={props.isAssessing}
					isNextWordReady={props.isNextWordReady}
					isWordSkipped={props.isWordSkipped}
				/>
				<Word word={props.word} />
				<Assessment
					wordPhonemes={props.wordPhonemes}
					userPhonemes={props.userPhonemes}
				/>
				<Controls
					onStartRecording={props.onStartRecording}
					onStopRecording={props.onStopRecording}
					isRecording={props.isRecording}
					isAssessing={props.isAssessing}
					isLoading={props.isLoading}
					isNextWordReady={props.isNextWordReady}
					userAudioUrl={props.userAudioUrl}
					onShowNextWord={props.onShowNextWord}
					audioUrl={props.wordAudioUrl}
					shouldReload={props.shouldReload}
					ftueViewModel={props.ftueViewModel}
				/>
			</main>
		</div>
	);
}

MainScreen.propTypes = {
	shouldReload: PropTypes.bool,
	errorCode: PropTypes.number,
	currentLevel: PropTypes.number,
	totalLevelSteps: PropTypes.number,
	currentLevelStep: PropTypes.number,
	completedLevelSteps: PropTypes.number,
	word: PropTypes.string,
	wordPhonemes: PropTypes.array,
	userPhonemes: PropTypes.array,
	wordAudioUrl: PropTypes.string,
	userAudioUrl: PropTypes.string,
	isRecording: PropTypes.bool,
	isAssessing: PropTypes.bool,
	isLoading: PropTypes.bool,
	isNextWordReady: PropTypes.bool,
	isWordSkipped: PropTypes.bool,
	skippedWords: PropTypes.array,
	isLevelFinished: PropTypes.bool,
	onStartRecording: PropTypes.func,
	onStopRecording: PropTypes.func,
	onShowNextWord: PropTypes.func,
	onSearchBtnClicked: PropTypes.func,
	ftueViewModel: PropTypes.object,
};

export default MainScreen;
