import Debug from './Debug';

export default function adjustScreenSize() {
	Debug.log('[adjustScreenSize] Init');

	const setViewHeight = () => {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	};
	setViewHeight();
	window.addEventListener('resize', () => {
		setViewHeight();
	});
}
