import { ErrorFlags } from 'Enums';
import Debug from 'utils/Debug';

const SILENCE_OR_WRONG_WORD_ERROR_RESPONSE = 'InitialSilenceTimeout';

export default class AssessmentService {
	//------------------------------------------------------
	static async evaluateWav(word, wav) {
		try {
			const response = await fetch(`/api/assess-audio?word=${word}`, {
				method: 'POST',
				headers: {
					'Content-type': 'application/octet-stream',
				},
				body: wav,
			});
			const json = await response.json();

			if (json.result?.error === SILENCE_OR_WRONG_WORD_ERROR_RESPONSE)
				json.result.error = ErrorFlags.SILENCE_OR_WRONG_WORD;

			// Debug.info(json);
			return json.result;
		} catch (err) {
			Debug.error(`[AssessmentService] ${err}`);
			return null;
		}
	}

	//------------------------------------------------------
	static async getBlobPhonemes(word, blob) {
		try {
			const response = await fetch(`/api/assess-audio?word=${word}`, {
				method: 'POST',
				headers: {
					'Content-type': 'application/octet-stream',
				},
				body: blob,
			});
			const json = await response.json();
			// Debug.info(json);
			return json.result;
		} catch (err) {
			Debug.error(`[AssessmentService] ${err}`);
			return null;
		}
	}
	//------------------------------------------------------
}
