function SpinnerButton() {
	return (
		<div className="flex justify-center items-center bg-gray-500 text-gray-700 mx-auto -mt-[10vh] w-[15vh] h-[15vh] rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-3xl">
			<svg
				className="w-[10vh] h-[10vh] drop-shadow"
				viewBox="0 0 38 38"
				xmlns="http://www.w3.org/2000/svg"
			>
				<defs>
					<linearGradient
						x1="8.042%"
						y1="0%"
						x2="65.682%"
						y2="23.865%"
						id="a"
					>
						<stop
							stopColor="currentColor"
							stopOpacity="0"
							offset="0%"
						/>
						<stop
							stopColor="currentColor"
							stopOpacity=".631"
							offset="63.146%"
						/>
						<stop stopColor="currentColor" offset="100%" />
					</linearGradient>
				</defs>
				<g fill="none" fillRule="evenodd">
					<g transform="translate(1 1)">
						<path
							d="M36 18c0-9.94-8.06-18-18-18"
							id="Oval-2"
							stroke="url(#a)"
							strokeWidth="2"
						>
							<animateTransform
								attributeName="transform"
								type="rotate"
								from="0 18 18"
								to="360 18 18"
								dur="0.9s"
								repeatCount="indefinite"
							/>
						</path>
						<circle fill="currentColor" cx="36" cy="18" r="1">
							<animateTransform
								attributeName="transform"
								type="rotate"
								from="0 18 18"
								to="360 18 18"
								dur="0.9s"
								repeatCount="indefinite"
							/>
						</circle>
					</g>
				</g>
			</svg>
		</div>
	);
}

export default SpinnerButton;
