export default function RecordingStep() {
	return (
		<div className="relative">
			<div className="absolute z-30 -top-20 -translate-y-full -translate-x-2/4 bg-gray-800 p-6 rounded drop-shadow-xl min-w-[40vh] text-xl font-normal text-gray-300">
				<div className="w-[2vh] h-[2vh] absolute bg-gray-800 left-[15vh] bottom-[-1vh] rotate-45"></div>
				Click recording and speak so we can evaluate your pronunciation
			</div>
		</div>
	);
}
