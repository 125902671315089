import { useEffect, useReducer, useState } from 'react';
import AssessmentService from 'services/AssessmentService';
import RecordingService from 'services/RecordingService';
import PronounceService from 'services/PronounceService';
import Debug from 'utils/Debug';
import ProgressionService from 'services/ProgressionService';
import { ErrorFlags, PopupMessages } from 'Enums';
import Consts from 'Consts';
// import NotificationService from 'services/NotificationService';
import FTUEService from 'services/FTUEService';
import AnalyticsService from 'services/AnalyticsService';

export default function useAppManager() {
	//------------------------------------------------------
	const [isLoading, setIsLoading] = useState(true);
	const [currentWord, setCurrentWord] = useState(null);
	const [wordPhonemes, setWordPhonemes] = useState(null);
	const [userPhonemes, setUserPhonemes] = useState(null);
	const [wordAudioUrl, setWordAudioUrl] = useState(null);
	const [userAudioUrl, setUserAudioUrl] = useState(null);
	const [isRecording, setIsRecording] = useState(false);
	const [isAssessing, setIsAssessing] = useState(false);
	const [topAccuracyScore, setTopAccuracyScore] = useState(0);
	const [errorCode, setErrorCode] = useState(null);
	const [recId, setRecId] = useState(0);
	const [stopRecId, setStopRecId] = useState(null);
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [popupMessage, setPopupMessage] = useState(null);

	// Helper method to force React refresh UI
	const [, refreshView] = useReducer((x) => x + 1, 0);

	//------------------------------------------------------
	async function setWordData(word) {
		setUserPhonemes(null);

		// TODO: revoke old Blob object
		const [audioUrl, phonemes, accuracyScore, error] =
			await PronounceService.getWordAudioUrl(word);
		setWordAudioUrl(audioUrl);
		setWordPhonemes(phonemes);
		setTopAccuracyScore(accuracyScore);
		setCurrentWord(word);

		if (error) {
			setErrorCode(error);
		}
	}

	//------------------------------------------------------
	function onBlur() {
		Debug.log('[AppManager] Tab is blurred');

		if (RecordingService.IsActive) RecordingService.Instance.deinit();
	}

	//------------------------------------------------------
	async function injectSearchQueryIfFound() {
		const url = new URL(window.location);
		let queryParam = url.searchParams.get('q');
		if (url.pathname !== '/search' || !queryParam) return;

		queryParam = queryParam.toLowerCase();

		// Do lazy loading, because dictionary is heavy
		const dictionary = (await import('data/word-suggestion.json')).default;

		if (dictionary.includes(queryParam) === false) return;

		ProgressionService.injectWordIntoProgress(queryParam);
	}

	//------------------------------------------------------
	async function init() {
		Debug.log('[AppManager] Init');
		setIsLoading(true);

		if (process.env.NODE_ENV === 'development') {
			localStorage.clear();
		}

		FTUEService.init();

		AnalyticsService.init();
		AnalyticsService.trackPageview({ page: Consts.INDEX_PAGE });

		window.addEventListener('blur', onBlur);

		const onProgressCleared = () =>
			setPopupMessage(PopupMessages.PROGRESS_CLEARED);

		ProgressionService.init(onProgressCleared);
		await injectSearchQueryIfFound();
		// NotificationService.scheduleNotification();

		await setWordData(ProgressionService.currentWord);

		setIsLoading(false);
	}

	//------------------------------------------------------
	function destroy() {
		Debug.log('[AppManager] Destroy');
		window.removeEventListener('blur', onBlur);
	}

	//------------------------------------------------------
	// Register init and destroy callbacks
	useEffect(() => {
		init();
		return () => {
			destroy();
		};
	}, []);

	//------------------------------------------------------
	async function startRecording() {
		Debug.info('[AppManager] Start Recording');

		setErrorCode(null);

		setIsLoading(true);

		if (RecordingService.IsActive === false)
			await RecordingService.Instance.init();

		setIsLoading(false);

		setIsRecording(true);

		const autoStopCallback = () => setStopRecId(recId);
		RecordingService.Instance.startRecording(
			autoStopCallback,
			wordPhonemes.length
		);
	}

	//------------------------------------------------------
	async function stopRecording() {
		if (isRecording === false) return;

		Debug.info('[AppManager] Stop Recording');

		setIsRecording(false);
		setRecId((id) => id + 1);
		setIsAssessing(true);
		const [audioUrl, wavFile] =
			await RecordingService.Instance.stopAndGetDataAsync();

		setUserAudioUrl(audioUrl);

		const result = await AssessmentService.evaluateWav(
			currentWord,
			wavFile
		);

		if (result.error) {
			Debug.warn(`[AppManager] - Error: ${result.error}`);
			setErrorCode(result.error);
			setUserPhonemes(null);
			setIsAssessing(false);
			return;
		}

		Debug.log(`[AppManager] - topAccuracyScore: ${topAccuracyScore}`);
		Debug.log(`[AppManager] - accuracyScore: ${result.AccuracyScore}`);

		setUserPhonemes(result.Phonemes);

		await ProgressionService.evaluateCurrentStep(
			wordPhonemes,
			result.Phonemes
		);

		AnalyticsService.trackWordAttempt({ word: currentWord });

		setIsAssessing(false);
	}

	//------------------------------------------------------
	useEffect(() => {
		if (stopRecId === recId) stopRecording();
	}, [stopRecId]);

	//------------------------------------------------------
	async function showNextWord() {
		setIsLoading(true);
		setWordAudioUrl(null);
		setUserAudioUrl(null);

		if (ProgressionService.isLastLevelStep)
			setPopupMessage(PopupMessages.LEVEL_COMPLETED);

		await ProgressionService.advanceUserProgress();

		await setWordData(ProgressionService.currentWord);
		setIsLoading(false);
	}

	//------------------------------------------------------
	async function injectSearchWord(searchKeyword) {
		setIsSearchOpen(false);

		if (searchKeyword === currentWord) return;

		ProgressionService.injectWordIntoProgress(searchKeyword);

		setIsLoading(true);

		setWordAudioUrl(null);
		setUserAudioUrl(null);

		await setWordData(ProgressionService.currentWord);

		setIsLoading(false);
	}

	//------------------------------------------------------
	function onSearchBtnClicked() {
		setIsSearchOpen(true);
		AnalyticsService.trackPageview({ page: Consts.SEARCH_PAGE });
	}

	//------------------------------------------------------
	function onOkPopupClicked() {
		switch (popupMessage) {
			case PopupMessages.INSTALL_ICON:
				break;
			default:
				break;
		}

		setPopupMessage(null);
	}

	//------------------------------------------------------
	function createMainViewModel() {
		return {
			errorCode,
			currentLevel: ProgressionService.level,
			totalLevelSteps: ProgressionService.totalLevelSteps,
			currentLevelStep: ProgressionService.currentLevelStep,
			completedLevelSteps: ProgressionService.completedLevelSteps,
			word: currentWord,
			wordPhonemes,
			userPhonemes,
			wordAudioUrl,
			userAudioUrl,
			isRecording,
			isAssessing,
			isLoading,
			isNextWordReady: ProgressionService.isCurrentStepPassed,
			isWordSkipped: ProgressionService.isCurrentStepSkipped,
			skippedWords: ProgressionService.skippedSteps,
			isLevelFinished: ProgressionService.isCurrentLevelPassed,
			shouldReload:
				errorCode === ErrorFlags.CANT_DOWNLOAD_WORD_PRONUNCIATION,
			onStartRecording: startRecording,
			onStopRecording: stopRecording,
			onShowNextWord: showNextWord,
			onSearchBtnClicked,
		};
	}

	//------------------------------------------------------
	function createSearchViewModel() {
		if (isSearchOpen === false) return null;

		return {
			onCloseBtnClicked: () => {
				setIsSearchOpen(false);
			},
			onSearchKeywrodClicked: injectSearchWord,
		};
	}

	//------------------------------------------------------
	function createPopupModel() {
		if (popupMessage === null) return null;

		return {
			popupMessage,
			onOkBtnClicked: onOkPopupClicked,
			onCancelBtnClicked: () => setPopupMessage(null),
		};
	}

	//------------------------------------------------------
	return {
		mainViewModel: createMainViewModel(),
		searchViewModel: createSearchViewModel(),
		popupViewModel: createPopupModel(),
		ftueViewModel: FTUEService.createViewModel({
			refreshViewCallback: refreshView,
			popupMessage,
		}),
		// ftueViewModel: null,
	};

	//------------------------------------------------------
}
