import PropTypes from 'prop-types';
import { ErrorFlags, PhonemeMarks } from 'Enums';
import phonemesSuggestions from 'data/phonemes-suggestions.json';

export default function Suggestion(props) {
	//------------------------------------------------------
	const {
		wordPhonemes,
		userPhonemes,
		errorCode,
		isRecording,
		isAssessing,
		isNextWordReady,
		isWordSkipped,
	} = props;

	let badPhonemes = [];
	if (
		isAssessing === false &&
		isRecording === false &&
		errorCode === null &&
		wordPhonemes !== null &&
		wordPhonemes?.length === userPhonemes?.length
	) {
		badPhonemes = userPhonemes.filter(
			(phoneme, index) =>
				wordPhonemes[index].AccuracyScore * PhonemeMarks.BAD >
					phoneme.AccuracyScore ||
				(wordPhonemes[index].NBestPhonemes[0].Phoneme !==
					phoneme.NBestPhonemes[0].Phoneme &&
					wordPhonemes[index].Phoneme !==
						phoneme.NBestPhonemes[0].Phoneme)
		);
	}

	//------------------------------------------------------
	let goodPhonemes = [];
	if (
		isAssessing === false &&
		isRecording === false &&
		errorCode === null &&
		wordPhonemes !== null &&
		wordPhonemes?.length === userPhonemes?.length &&
		badPhonemes.length === 0
	) {
		goodPhonemes = userPhonemes.filter(
			(phoneme, index) =>
				wordPhonemes[index].AccuracyScore * PhonemeMarks.GOOD >
				phoneme.AccuracyScore
		);
	}

	//------------------------------------------------------
	return (
		<div className="w-[90%] flex flex-col justify-center items-center basis-0 grow-[1] mx-10 text-gray-300 text-xl drop-shadow">
			{errorCode === ErrorFlags.SILENCE_OR_WRONG_WORD && (
				<div className="flex flex-col justify-center items-center text-center">
					<p className="text-red-300 text-lg italic m-2">
						Your audio was silent or you pronounced a different
						word.
					</p>
					Being in a quiet place improves assessment.
				</div>
			)}

			{errorCode === ErrorFlags.CANT_DOWNLOAD_WORD_PRONUNCIATION && (
				<div className="flex justify-center items-center text-center">
					<p className="text-red-300 text-lg italic m-2">
						Can&#39;t download word audio.
					</p>
					Plese refresh the page.
				</div>
			)}

			{isNextWordReady && isWordSkipped === false && (
				<div className="flex justify-center items-center text-center">
					<p className="text-green-300 text-2xl italic m-2">PASSED</p>
				</div>
			)}

			{isWordSkipped && (
				<div className="flex justify-center items-center text-center">
					<p className="text-yellow-300 text-2xl italic m-2">
						SKIPPED
					</p>
				</div>
			)}

			{badPhonemes.length > 0 && (
				<>
					{badPhonemes[0].Phoneme !==
						badPhonemes[0].NBestPhonemes[0].Phoneme && (
						<div className="text-lg italic m-2">
							You said{' '}
							<span className="text-red-400">
								{badPhonemes[0].NBestPhonemes[0].Phoneme}
							</span>
							, try{' '}
							<span className="text-green-400">
								{badPhonemes[0].Phoneme}
							</span>
						</div>
					)}
					<div>
						Pronounce{' '}
						<span className="text-green-400">
							{badPhonemes[0].Phoneme}
						</span>{' '}
						as in{' '}
						<span className="font-bold">
							{phonemesSuggestions[badPhonemes[0].Phoneme]}
						</span>
						<br />
					</div>
				</>
			)}

			{goodPhonemes.length > 0 && (
				<div>
					Pronounce{' '}
					<span className="text-yellow-400">
						{goodPhonemes[0].Phoneme}
					</span>{' '}
					as in{' '}
					<span className="font-bold">
						{phonemesSuggestions[goodPhonemes[0].Phoneme]}
					</span>
				</div>
			)}
		</div>
	);
}

Suggestion.propTypes = {
	errorCode: PropTypes.number,
	wordPhonemes: PropTypes.array,
	userPhonemes: PropTypes.array,
	isRecording: PropTypes.bool,
	isAssessing: PropTypes.bool,
	isNextWordReady: PropTypes.bool,
	isWordSkipped: PropTypes.bool,
};
