const serviceWorkerRegistration = () => {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register(
			`${process.env.PUBLIC_URL}/serviceWorker.js`
		);

		// navigator.serviceWorker.ready
		// 	.then((registration) => {
		// 		registration.unregister();
		// 	})
		// 	.catch((error) => {
		// 		console.error(error.message);
		// 	});
	}
};

export default serviceWorkerRegistration;
