import PropTypes from 'prop-types';
import AudioReferenceButton from './controls/AudioReferenceButton';
import ReloadButton from './controls/ReloadButton';
import ReplayButton from './controls/ReplayButton';
import ShowNextWordButton from './controls/ShowNextWordButton';
import SpinnerButton from './controls/SpinnerButton';
import StartRecodingButton from './controls/StartRecordingButton';
import StopRecodingButton from './controls/StopRecordingButton';

const ActionButtonStates = Object.freeze({
	SPINNER: 0,
	STOP_RECORDING: 1,
	NEXT_WORD: 2,
	START_RECORDING: 3,
	RELOAD: 4,
});

function Controls(props) {
	let currentButtonState = null;

	if (props.isAssessing || props.isLoading)
		currentButtonState = ActionButtonStates.SPINNER;
	else if (props.shouldReload) currentButtonState = ActionButtonStates.RELOAD;
	else if (props.isRecording)
		currentButtonState = ActionButtonStates.STOP_RECORDING;
	else if (props.isNextWordReady)
		currentButtonState = ActionButtonStates.NEXT_WORD;
	else currentButtonState = ActionButtonStates.START_RECORDING;

	return (
		<div className="bg-gray-500 w-full rounded-b-3xl flex flex-row justify-center items-center basis-0 grow-[1]">
			<div className="grow-[1] flex justify-center text-gray-300">
				<AudioReferenceButton
					audioUrl={props.audioUrl}
					ftueViewModel={props.ftueViewModel}
				/>
			</div>
			<div className="grow-[1] flex justify-center">
				{currentButtonState === ActionButtonStates.RELOAD && (
					<ReloadButton />
				)}
				{currentButtonState === ActionButtonStates.SPINNER && (
					<SpinnerButton />
				)}
				{currentButtonState === ActionButtonStates.STOP_RECORDING && (
					<StopRecodingButton
						onStopRecording={props.onStopRecording}
					/>
				)}
				{currentButtonState === ActionButtonStates.NEXT_WORD && (
					<ShowNextWordButton onShowNextWord={props.onShowNextWord} />
				)}
				{currentButtonState === ActionButtonStates.START_RECORDING && (
					<StartRecodingButton
						onStartRecording={props.onStartRecording}
						ftueViewModel={props.ftueViewModel}
					/>
				)}
			</div>
			<div className="grow-[1] flex justify-center">
				<ReplayButton
					audioUrl={props.userAudioUrl}
					ftueViewModel={props.ftueViewModel}
				/>
			</div>
		</div>
	);
}

Controls.propTypes = {
	shouldReload: PropTypes.bool,
	isRecording: PropTypes.bool,
	isAssessing: PropTypes.bool,
	isLoading: PropTypes.bool,
	isNextWordReady: PropTypes.bool,
	onStartRecording: PropTypes.func,
	onStopRecording: PropTypes.func,
	onShowNextWord: PropTypes.func,
	audioUrl: PropTypes.string,
	userAudioUrl: PropTypes.string,
	ftueViewModel: PropTypes.object,
};

export default Controls;
