import PropTypes from 'prop-types';

function StopRecodingButton(props) {
	return (
		<button
			onClick={props.onStopRecording}
			className="flex justify-center items-center bg-gray-300 text-gray-700 mx-auto -mt-[10vh] w-[15vh] h-[15vh] rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-3xl"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="w-[10vh] h-[10vh] text-red-500 drop-shadow"
				fill="currentColor"
				viewBox="0 0 16 16"
			>
				<path
					fillRule="evenodd"
					d="M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10z"
				/>
			</svg>
		</button>
	);
}

StopRecodingButton.propTypes = {
	onStopRecording: PropTypes.func,
};

export default StopRecodingButton;
