import ReplayStep from 'components/ftue/ReplayStep';
import { FTUESteps } from 'Enums';
import PropTypes from 'prop-types';

const AUDIO_TAG_ID = 'userWordAudio';

function ReplayButton(props) {
	function playSound() {
		const player = document.getElementById(AUDIO_TAG_ID);
		player.currentTime = 0;
		player.play();

		if (
			props.ftueViewModel !== null &&
			props.ftueViewModel.ftueStep === FTUESteps.REPLAY
		) {
			props.ftueViewModel.onClose();
		}
	}

	return (
		<>
			<audio id={AUDIO_TAG_ID} src={props.audioUrl} />
			<button
				onClick={playSound}
				disabled={props.audioUrl === null ? true : ''}
				className={props.audioUrl === null ? 'text-gray-400 ' : ''}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="currentColor"
					className="w-[5vh] h-[5vh] drop-shadow"
					viewBox="0 0 16 16"
				>
					<path
						fillRule="evenodd"
						d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5zm-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zm-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5zm12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5z"
					/>
				</svg>

				{props.audioUrl !== null &&
					props.ftueViewModel !== null &&
					props.ftueViewModel.ftueStep === FTUESteps.REPLAY && (
						<ReplayStep />
					)}
			</button>
		</>
	);
}

ReplayButton.propTypes = {
	audioUrl: PropTypes.string,
	ftueViewModel: PropTypes.object,
};

export default ReplayButton;
