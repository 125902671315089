function ReloadButton() {
	return (
		<button
			onClick={() => {
				window.location.reload();
			}}
			className="flex justify-center items-center bg-gray-300 text-gray-700 mx-auto -mt-[10vh] w-[15vh] h-[15vh] rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-3xl"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="w-[10vh] h-[10vh] drop-shadow"
				fill="currentColor"
				viewBox="0 0 16 16"
			>
				<path
					fillRule="evenodd"
					d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
				/>
				<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
			</svg>
		</button>
	);
}

export default ReloadButton;
