import InstallAppStep from 'components/ftue/InstallAppStep';
import { FTUESteps } from 'Enums';
import PropTypes from 'prop-types';

function FTUEScreen(props) {
	//------------------------------------------------------
	const isFullScreen = props.ftueStep === FTUESteps.INSTALL_PROMPT;

	//------------------------------------------------------
	return (
		<>
			{isFullScreen && (
				<div className="backdrop-brightness-[30%] absolute min-h-screen backdrop-blur-md flex flex-col justify-center items-center min-w-full">
					<div className="flex flex-col min-h-screen w-[80%] max-w-[66vh] mx-3 text-3xl justify-around items-center drop-shadow-md">
						<InstallAppStep onClose={props.onClose} />
					</div>
				</div>
			)}
		</>
	);
}

FTUEScreen.propTypes = {
	ftueStep: PropTypes.number,
	onClose: PropTypes.func,
};

export default FTUEScreen;
