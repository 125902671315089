import { FTUESteps, PopupMessages } from 'Enums';
// import NotificationService from 'services/NotificationService';
import ProgressionService from 'services/ProgressionService';
import AnalyticsService from './AnalyticsService';

const FTUE_PROGRESS_KEY = 'ftue_progress';

export default class FTUEService {
	//------------------------------------------------------
	static progress = null;

	static activeStep = null;

	//------------------------------------------------------
	static loadProgress() {
		const savedFtueProgress = localStorage.getItem(FTUE_PROGRESS_KEY);
		this.progress = JSON.parse(savedFtueProgress) ?? new Set();
	}

	//------------------------------------------------------
	static saveProgress() {
		localStorage.setItem(FTUE_PROGRESS_KEY, JSON.stringify(this.progress));
	}

	//------------------------------------------------------
	static addProgress(step) {
		this.progress.add(step);
		this.saveProgress();
	}

	//------------------------------------------------------
	static init() {
		this.loadProgress();
	}

	//------------------------------------------------------
	static createViewModel({ refreshViewCallback, popupMessage } = {}) {
		if (this.progress === null) return null;

		//----
		// Reference audio button step
		if (
			ProgressionService.level === 1 &&
			this.progress.has(FTUESteps.AUDIO_REFERENCE) === false
		) {
			if (this.activeStep === null) {
				this.activeStep = FTUESteps.AUDIO_REFERENCE;
				AnalyticsService.trackFtueStepStarted({
					stepName: 'AUDIO_REFERENCE',
				});
			}

			return {
				ftueStep: FTUESteps.AUDIO_REFERENCE,
				onClose: () => {
					AnalyticsService.trackFtueStepEnded({
						stepName: 'AUDIO_REFERENCE',
						skipped: false,
					});

					this.addProgress(FTUESteps.AUDIO_REFERENCE);
					this.activeStep = null;
					refreshViewCallback();
				},
			};
		}

		//----
		// Recording button step
		if (
			ProgressionService.level === 1 &&
			this.progress.has(FTUESteps.AUDIO_REFERENCE) &&
			this.progress.has(FTUESteps.RECORDING) === false
		) {
			if (this.activeStep === null) {
				this.activeStep = FTUESteps.RECORDING;
				AnalyticsService.trackFtueStepStarted({
					stepName: 'RECORDING',
				});
			}

			return {
				ftueStep: FTUESteps.RECORDING,
				onClose: () => {
					AnalyticsService.trackFtueStepEnded({
						stepName: 'RECORDING',
						skipped: false,
					});

					this.addProgress(FTUESteps.RECORDING);
					this.activeStep = null;
					refreshViewCallback();
				},
			};
		}

		//----
		// Replay button step
		if (
			ProgressionService.level === 1 &&
			this.progress.has(FTUESteps.RECORDING) &&
			this.progress.has(FTUESteps.REPLAY) === false
		) {
			if (this.activeStep === null) {
				this.activeStep = FTUESteps.REPLAY;
				AnalyticsService.trackFtueStepStarted({
					stepName: 'REPLAY',
				});
			}

			return {
				ftueStep: FTUESteps.REPLAY,
				onClose: () => {
					AnalyticsService.trackFtueStepEnded({
						stepName: 'REPLAY',
						skipped: false,
					});

					this.addProgress(FTUESteps.REPLAY);
					this.activeStep = null;
					refreshViewCallback();
				},
			};
		}

		//----
		// Install step
		if (
			ProgressionService.level === 2 &&
			popupMessage === PopupMessages.LEVEL_COMPLETED &&
			this.progress.has(FTUESteps.INSTALL_PROMPT) === false
		) {
			if (this.activeStep === null) {
				this.activeStep = FTUESteps.INSTALL_PROMPT;
				AnalyticsService.trackFtueStepStarted({
					stepName: 'INSTALL_PROMPT',
				});
			}

			return {
				ftueStep: FTUESteps.INSTALL_PROMPT,
				onClose: ({ skipped = false } = {}) => {
					AnalyticsService.trackFtueStepEnded({
						stepName: 'INSTALL_PROMPT',
						skipped,
					});

					this.addProgress(FTUESteps.INSTALL_PROMPT);
					this.activeStep = null;
					refreshViewCallback();
				},
			};
		}
		//----
		// Subscribe to Push step
		// if (
		// 	ProgressionService.level === 3 &&
		// 	popupMessage === PopupMessages.LEVEL_COMPLETED &&
		// 	NotificationService.hasPermissionResponse === false
		// ) {
		// 	NotificationService.requestPermissions({
		// 		onResponseCallback: refreshViewCallback,
		// 	});
		// }
		//----

		this.activeStep = null;
		return null;
	}
	//------------------------------------------------------
}
