const Debug = console;
const quiet = !process.env.NODE_ENV || process.env.NODE_ENV === 'production';
const noop = () => {};

if (Debug && quiet) {
	Debug.info = noop;
	Debug.log = noop;
	Debug.warn = noop;
}

export default Debug;
