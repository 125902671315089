import Debug from 'utils/Debug';
import dictionary from 'data/word-dict.json';
import levelConfigs from 'data/level-configs.json';
import { PhonemeMarks } from 'Enums';
import Consts from 'Consts';
import AnalyticsService from './AnalyticsService';

const USER_PROGRESS_KEY = 'user_progress';
const LEVEL_PROGRESS_KEY = 'level_progress';
const CURRENT_LEVEL_KEY = 'current_level';
const CURRENT_INDEX_KEY = 'current_index';
const APP_VERSION_KEY = 'app_version';
const MAX_ATTEMPTS_PER_WORD = 10;

export default class ProgressionService {
	//------------------------------------------------------
	static currentLevel = 0;

	static currentIndex = 0;

	static levelProgress = null;

	static userProgress = {};

	//------------------------------------------------------
	static get totalLevelSteps() {
		if (this.levelProgress == null) return 0;

		return this.levelProgress.length;
	}

	//------------------------------------------------------
	static get level() {
		return this.currentLevel;
	}

	//------------------------------------------------------
	static get currentLevelStep() {
		return this.currentIndex;
	}

	//------------------------------------------------------
	static get currentWord() {
		if (this.levelProgress === null) return null;

		return this.levelProgress[this.currentIndex].word;
	}

	//------------------------------------------------------
	static get completedLevelSteps() {
		if (this.levelProgress === null) return 0;

		return this.levelProgress.filter((ld) => ld.passed).length;
	}

	//------------------------------------------------------
	static get skippedSteps() {
		return this.levelProgress === null
			? [false]
			: this.levelProgress.map((lp) => lp.skipped);
	}

	//------------------------------------------------------
	static get isLastLevelStep() {
		if (this.levelProgress == null) return false;

		return this.currentIndex === this.levelProgress.length - 1;
	}

	//------------------------------------------------------
	static get isCurrentStepPassed() {
		if (this.levelProgress === null) return false;

		return this.levelProgress[this.currentIndex].passed;
	}

	//------------------------------------------------------
	static get isCurrentStepSkipped() {
		if (this.levelProgress === null) return false;

		return this.levelProgress[this.currentIndex].skipped;
	}

	//------------------------------------------------------
	static get isCurrentLevelPassed() {
		if (this.levelProgress === null) return false;

		return this.levelProgress[this.levelProgress.length - 1].passed;
	}

	//------------------------------------------------------
	static init(onProgressCleared) {
		const savedAppVersion = localStorage.getItem(APP_VERSION_KEY);
		const appVersion =
			JSON.parse(savedAppVersion) ?? Consts.SAVE_DATA_VERSION;

		if (appVersion < Consts.SAVE_DATA_VERSION) {
			localStorage.clear();
			onProgressCleared();
		}

		const savedUserProgress = localStorage.getItem(USER_PROGRESS_KEY);
		this.userProgress = JSON.parse(savedUserProgress) ?? {};
		const savedLevelProgress = localStorage.getItem(LEVEL_PROGRESS_KEY);
		this.levelProgress = JSON.parse(savedLevelProgress);
		const savedCurrentLevel = localStorage.getItem(CURRENT_LEVEL_KEY);
		this.currentLevel = JSON.parse(savedCurrentLevel) ?? 0;
		const savedCurrentIndex = localStorage.getItem(CURRENT_INDEX_KEY);
		this.currentIndex = JSON.parse(savedCurrentIndex) ?? 0;

		if (this.levelProgress === null) this.generateNewLevelData();
	}

	//------------------------------------------------------
	static saveState(keys) {
		localStorage.setItem(
			APP_VERSION_KEY,
			JSON.stringify(Consts.SAVE_DATA_VERSION)
		);

		if (keys.includes(USER_PROGRESS_KEY)) {
			localStorage.setItem(
				USER_PROGRESS_KEY,
				JSON.stringify(this.userProgress)
			);
		}

		if (keys.includes(LEVEL_PROGRESS_KEY)) {
			localStorage.setItem(
				LEVEL_PROGRESS_KEY,
				JSON.stringify(this.levelProgress)
			);
		}

		if (keys.includes(CURRENT_LEVEL_KEY)) {
			localStorage.setItem(
				CURRENT_LEVEL_KEY,
				JSON.stringify(this.currentLevel)
			);
		}

		if (keys.includes(CURRENT_INDEX_KEY)) {
			localStorage.setItem(
				CURRENT_INDEX_KEY,
				JSON.stringify(this.currentIndex)
			);
		}
	}

	//------------------------------------------------------
	static async evaluateCurrentStep(wordPhonemes, userPhonemes) {
		// Output debug info
		Debug.log(
			`[ProgressionService] - word phonemes: ${wordPhonemes
				.map((p) => `${p.Phoneme} - ${p.AccuracyScore}`)
				.join(' | ')}`
		);
		Debug.log(
			`[ProgressionService] - word BNest phonems: ${wordPhonemes
				.map((p) => `${p.NBestPhonemes[0].Phoneme}`)
				.join(' | ')}`
		);
		Debug.log(
			`[ProgressionService] - user phonemes: ${userPhonemes
				.map((p) => `${p.Phoneme} - ${p.AccuracyScore}`)
				.join(' | ')}`
		);
		Debug.log(
			`[ProgressionService] - user BNest phonems: ${userPhonemes
				.map((p) => `${p.NBestPhonemes[0].Phoneme}`)
				.join(' | ')}`
		);

		const topAccuracy = wordPhonemes.reduce(
			(prev, phoneme) => prev + phoneme.AccuracyScore,
			0
		);
		const userAccuracy = userPhonemes.reduce(
			(prev, phoneme) => prev + phoneme.AccuracyScore,
			0
		);

		// Calculate scores
		// Special case for two digit letters, Azure struggles to recognize them, so we lower requirements
		const minScoreThreshold = wordPhonemes.length <= 2 ? 0.5 : 0.85;
		const isTotalScoreAcceptable =
			topAccuracy * minScoreThreshold < userAccuracy;
		let isPhonemeScoreAcceptable = true;
		userPhonemes.forEach((phoneme, idx) => {
			isPhonemeScoreAcceptable &&=
				phoneme.AccuracyScore >=
					PhonemeMarks.BAD * wordPhonemes[idx].AccuracyScore &&
				(wordPhonemes[idx].NBestPhonemes[0].Phoneme ===
					phoneme.NBestPhonemes[0].Phoneme ||
					wordPhonemes[idx].Phoneme ===
						phoneme.NBestPhonemes[0].Phoneme);
		});

		this.levelProgress[this.currentIndex].attempts += 1;

		if (isTotalScoreAcceptable && isPhonemeScoreAcceptable)
			this.levelProgress[this.currentIndex].passed = true;

		if (
			this.levelProgress[this.currentIndex].attempts >=
				MAX_ATTEMPTS_PER_WORD &&
			this.levelProgress[this.currentIndex].passed === false
		) {
			this.levelProgress[this.currentIndex].passed = true;
			this.levelProgress[this.currentIndex].skipped = true;
		}

		this.saveState([LEVEL_PROGRESS_KEY]);
	}

	//------------------------------------------------------
	static async advanceUserProgress() {
		this.currentIndex += 1;
		this.saveState([CURRENT_INDEX_KEY]);

		if (
			this.levelProgress === null ||
			this.currentIndex === this.levelProgress.length
		) {
			// We reached end of the level
			this.saveCurrentLevelData();
			this.generateNewLevelData();
		}
	}

	//------------------------------------------------------
	static saveCurrentLevelData() {
		this.levelProgress.forEach((item) => {
			this.userProgress[item.word] = {
				word: item.word,
				attempts: item.attempts,
				skipped: item.skipped,
				timeOfLastAttempt: Date.now(),
				timesPracticed:
					(this.userProgress[item.word]?.timesPracticed ?? 0) + 1,
			};
		});

		this.saveState([USER_PROGRESS_KEY]);
	}

	//------------------------------------------------------
	static addNewWords(wordAmount) {
		const totalWordAmount = this.levelProgress.length + wordAmount;
		dictionary.some((word) => {
			if (this.userProgress[word] !== undefined) return false;

			this.levelProgress.push({
				word,
				attempts: 0,
				passed: false,
				skipped: false,
			});

			return this.levelProgress.length >= totalWordAmount;
		});
	}

	//------------------------------------------------------
	static addOldWords(wordAmount) {
		if (wordAmount === 0) return;

		const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000);
		const oldWords = Object.values(this.userProgress).filter(
			// eslint-disable-next-line no-unused-vars
			// (value) => true
			(value) => value.timeOfLastAttempt <= twelveHoursAgo
		);

		oldWords.sort((a, b) => {
			if (a.skipped === true) return -1;
			if (b.skipped === true) return 1;

			if (a.attempts > b.attempts) return -1;
			if (a.attempts < b.attempts) return 1;

			if (a.timesPracticed > b.timesPracticed) return 1;
			if (a.timesPracticed < b.timesPracticed) return -1;

			if (a.timeOfLastAttempt > b.timeOfLastAttempt) return 1;
			if (a.timeOfLastAttempt < b.timeOfLastAttempt) return -1;

			return 0;
		});

		const totalWordAmount = this.levelProgress.length + wordAmount;
		oldWords.some((wordProgress) => {
			this.levelProgress.push({
				word: wordProgress.word,
				attempts: 0,
				passed: false,
				skipped: false,
			});

			return this.levelProgress.length >= totalWordAmount;
		});
	}

	//------------------------------------------------------
	static generateNewLevelData() {
		this.currentLevel += 1;

		const levelConfig = levelConfigs.find(
			(config) => this.currentLevel <= config.level
		);

		// Random deviation from level word quantity to make level distinct from one another
		const newWordsQuantityDeviation = Math.floor(
			Math.random() * levelConfig.randomDeviation * 2 -
				levelConfig.randomDeviation
		);

		const finalNewWordAmount =
			levelConfig.newWordAmount + newWordsQuantityDeviation;

		this.levelProgress = [];
		this.addNewWords(finalNewWordAmount);
		this.addOldWords(levelConfig.oldWordAmount);

		this.currentIndex = 0;

		this.saveState([
			LEVEL_PROGRESS_KEY,
			CURRENT_LEVEL_KEY,
			CURRENT_INDEX_KEY,
		]);

		AnalyticsService.trackLevelStarted({ levelName: this.currentLevel });
	}

	//------------------------------------------------------
	static injectWordIntoProgress(word) {
		if (this.levelProgress[this.currentIndex].passed)
			this.currentIndex += 1;

		const indexInProgress = this.levelProgress
			.map((lp) => lp.word)
			.indexOf(word);

		if (indexInProgress !== -1) {
			// Handle case when we already passed this word in current progress,
			// we need to reduce current index because we are picking element from front part of array
			if (indexInProgress < this.currentIndex) this.currentIndex -= 1;

			this.levelProgress = this.levelProgress.filter(
				(step) => step.word !== word
			);
		}

		const itemToInsert = {
			word,
			attempts: 0,
			passed: false,
			skipped: false,
		};
		this.levelProgress.splice(this.currentIndex, 0, itemToInsert);

		this.saveState([LEVEL_PROGRESS_KEY, CURRENT_INDEX_KEY]);

		AnalyticsService.trackSearch({ word });
	}

	//------------------------------------------------------
}
