import PropTypes from 'prop-types';
// import Debug from 'utils/Debug';
import { PhonemeMarks } from 'Enums';

export default function Assessment(props) {
	//------------------------------------------------------
	const phonemeStyleSelector = (p1, p2) => {
		if (p2 === null) return '';

		if (
			p1.AccuracyScore * PhonemeMarks.BAD > p2.AccuracyScore ||
			(p1.NBestPhonemes[0].Phoneme !== p2.NBestPhonemes[0].Phoneme &&
				p1.Phoneme !== p2.NBestPhonemes[0].Phoneme)
		)
			return 'text-red-400';
		if (p1.AccuracyScore * PhonemeMarks.GOOD > p2.AccuracyScore)
			return 'text-yellow-400';
		return 'text-green-400';
	};

	//------------------------------------------------------
	let { wordPhonemes, userPhonemes } = props;

	if (wordPhonemes !== null && userPhonemes?.length !== wordPhonemes?.length)
		userPhonemes = wordPhonemes.map(() => null);

	if (
		wordPhonemes !== null &&
		userPhonemes?.length === wordPhonemes?.length
	) {
		wordPhonemes = wordPhonemes.map((p, index) => ({
			phoneme: p.Phoneme,
			style: phonemeStyleSelector(p, userPhonemes[index]),
		}));
	}

	//------------------------------------------------------
	// if (props.wordPhonemes !== null && props.userPhonemes !== null) {
	// 	Debug.log(
	// 		`wordPhonemes: ${props.wordPhonemes
	// 			.map((p) => p.AccuracyScore)
	// 			.join(' ')}`
	// 	);
	// 	Debug.log(
	// 		`userPhonemes: ${props.userPhonemes
	// 			.map((p) => p.AccuracyScore)
	// 			.join(' ')}`
	// 	);
	// }

	//------------------------------------------------------
	return (
		<div className="border-gray-400 w-full border-b shadow-inner-b flex flex-col justify-start items-center basis-0 grow-[1.5]">
			{wordPhonemes !== null && (
				<div className="flex text-3xl text-gray-400 drop-shadow-sm">
					/
					{wordPhonemes.map((p, index) => (
						<div className={p.style} key={index}>
							{p.phoneme}
							{wordPhonemes.length !== index + 1 && <>·</>}
						</div>
					))}
					/
				</div>
			)}
		</div>
	);

	//------------------------------------------------------
}

Assessment.propTypes = {
	wordPhonemes: PropTypes.array,
	userPhonemes: PropTypes.array,
};
