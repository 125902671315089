export const PhonemeMarks = Object.freeze({
	BAD: 0.5,
	GOOD: 0.75,
	EXCELLENT: 1.0,
});

export const ErrorFlags = Object.freeze({
	SILENCE_OR_WRONG_WORD: 1,
	CANT_DOWNLOAD_WORD_PRONUNCIATION: 2,
});

export const PopupMessages = Object.freeze({
	LEVEL_COMPLETED: 1,
	PROGRESS_CLEARED: 2,
	INSTALL_ICON: 3,
});

export const FTUESteps = Object.freeze({
	AUDIO_REFERENCE: 10,
	RECORDING: 20,
	REPLAY: 30,
	INSTALL_PROMPT: 100,
});
