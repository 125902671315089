import ReactGA from 'react-ga4';
import Consts from 'Consts';
import Debug from 'utils/Debug';

export default class AnalyticsService {
	//------------------------------------------------------
	static init() {
		Debug.log('[AnalyticsService] Init');

		const isDev = process.env.NODE_ENV !== 'production';
		// const isDev = false;
		ReactGA.initialize(Consts.TRACKING_ID, {
			testMode: isDev,
			// gaOptions: {
			// 	debug_mode: true,
			// },
			// gtagOptions: {
			// 	debug_mode: true,
			// },
		});
	}

	//------------------------------------------------------
	static trackPageview({ page }) {
		Debug.log(`[AnalyticsService] trackPageview: ${page}`);

		ReactGA.send({ hitType: 'pageview', page });
	}

	//------------------------------------------------------
	static trackWordAttempt({ word }) {
		Debug.log(`[AnalyticsService] trackWordAttempt: ${word}`);

		ReactGA.gtag('event', 'select_content', {
			content_type: 'word_attempt',
			item_id: word,
		});
	}

	//------------------------------------------------------
	static trackFtueStepStarted({ stepName }) {
		Debug.log(`[AnalyticsService] trackFtueStepStarted: ${stepName}`);

		ReactGA.gtag('event', 'tutorial_begin', {
			step_name: stepName,
		});
	}

	//------------------------------------------------------
	static trackFtueStepEnded({ stepName, skipped }) {
		Debug.log(
			`[AnalyticsService] trackFtueStepEnded: ${stepName} (skipped: ${skipped})`
		);

		ReactGA.gtag('event', 'tutorial_complete', {
			step_name: stepName,
			skipped,
		});
	}

	//------------------------------------------------------
	static trackLevelStarted({ levelName }) {
		Debug.log(`[AnalyticsService] trackLevelStarted: ${levelName}`);

		ReactGA.gtag('event', 'level_start', {
			level_name: levelName,
		});
	}

	//------------------------------------------------------
	static trackSearch({ word }) {
		Debug.log(`[AnalyticsService] trackSearch: ${word}`);

		ReactGA.gtag('event', 'search', {
			search_term: word,
		});
	}

	//------------------------------------------------------
}
