import PropTypes from 'prop-types';
import { useState } from 'react';
import dictionary from 'data/word-suggestion.json';

function SearchScreen(props) {
	const [suggestions, setSuggestions] = useState([]);

	function handleInput(event) {
		const searchKeyword = event.target.value.toLowerCase();
		setSuggestions(
			dictionary
				.filter((word) => word.startsWith(searchKeyword))
				.slice(0, 5)
		);
	}

	return (
		<div className="backdrop-brightness-[30%] absolute min-h-screen min-w-full backdrop-blur-md flex flex-col justify-center items-center">
			<div className="basis-0 grow-[4] flex flex-col justify-start items-center">
				<div className="mt-[10vh] flex flex-col justify-end items-center">
					<input
						className="w-[80%] bg-gray-300 rounded-lg font-bold text-3xl text-gray-700 p-2 ring-1 ring-gray-900/20"
						type="text"
						id="search"
						name="search"
						onChange={handleInput}
						autoComplete="off"
						autoFocus
					/>
				</div>
				<div className="m-5 flex flex-col justify-center items-center w-[70%]">
					{suggestions.map((word) => (
						<button
							onClick={() => props.onSearchKeywrodClicked(word)}
							key={word}
							className="m-1 p-2 text-xl bg-gray-600 w-full flex justify-center items-center rounded-lg ring-1 ring-gray-900/20"
						>
							{word}
						</button>
					))}
				</div>
			</div>
			<div className="basis-0 grow-[1] flex flex-col justify-center items-center">
				<button
					onClick={props.onCloseBtnClicked}
					className="flex justify-center items-center bg-gray-300 text-red-500 mx-auto -mt-[10vh] w-[10vh] h-[10vh] rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-3xl"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						className="w-[5vh] h-[5vh] drop-shadow"
						viewBox="0 0 16 16"
					>
						<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
					</svg>
				</button>
			</div>
		</div>
	);
}

SearchScreen.propTypes = {
	onCloseBtnClicked: PropTypes.func,
	onSearchKeywrodClicked: PropTypes.func,
};

export default SearchScreen;
