import PropTypes from 'prop-types';
import Bowser from 'bowser';
import { useEffect } from 'react';

function InstallAppStep(props) {
	let viewData = null;
	const browser = Bowser.getParser(window.navigator.userAgent);
	//------------------------------------------------------
	if (
		browser.getBrowserName() === 'Chrome' &&
		browser.getOSName() !== 'iOS'
	) {
		viewData = {
			screenShot: (
				<img
					src="chrome_install_demo.gif"
					className="max-h-[30vh]"
				></img>
			),
			instructions: (
				<>
					<li>
						Tap <b>&#10247;</b>
					</li>
					<li>
						Tap <b>Install MrSpeak...</b>
					</li>
					<li>
						Use like an <b>app</b>
					</li>
				</>
			),
		};
	}
	//------------------------------------------------------
	if (
		browser.getBrowserName() === 'Safari' &&
		browser.getOSName() === 'iOS'
	) {
		viewData = {
			screenShot: (
				<img src="ios_install_demo.gif" className="max-h-[30vh]"></img>
			),
			instructions: (
				<>
					<li>
						<div className="flex flex-row items-center">
							Tap
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-5 h-5 drop-shadow m-1"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z"
								/>
								<path
									fillRule="evenodd"
									d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z"
								/>
							</svg>
						</div>
					</li>
					<li>
						<div className="flex flex-row items-center">
							Tap{' '}
							<b className="flex flex-row items-center m-1">
								Add to Home Screen
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									className="w-5 h-5 drop-shadow m-1"
									viewBox="0 0 16 16"
								>
									<path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
									<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
								</svg>
							</b>
						</div>
					</li>
					<li>
						Use like an <b>app</b>
					</li>
				</>
			),
		};
	}
	//------------------------------------------------------
	if (
		browser.getBrowserName() === 'Microsoft Edge' &&
		browser.getOSName() !== 'iOS'
	) {
		viewData = {
			screenShot: (
				<img src="edge_install_demo.png" className="max-h-[30vh]"></img>
			),
			instructions: (
				<>
					<li>Tap the icon in navigation bar</li>
					<li>
						Tap <b>Install</b>
					</li>
					<li>
						Use like an <b>app</b>
					</li>
				</>
			),
		};
	}
	//------------------------------------------------------
	if (browser.getBrowserName() === 'Samsung Internet for Android') {
		viewData = {
			screenShot: (
				<img
					src="samsung_install_demo.jpg"
					className="max-h-[30vh]"
				></img>
			),
			instructions: (
				<>
					<li>Tap the icon in navigation bar</li>
					<li>
						Tap <b>Install</b>
					</li>
					<li>
						Use like an <b>app</b>
					</li>
				</>
			),
		};
	}
	//------------------------------------------------------
	// No proper platform/browser configuration found, close the step
	if (viewData === null) {
		useEffect(() => {
			props.onClose({ skipped: true });
		}, []);
		return null;
	}

	//------------------------------------------------------
	return (
		<>
			<div className="font-bold text-center">Add to Your Home Screen</div>
			<div className="text-center text-xl">
				We currently don&apos;t have <b>MrSpeak</b> app in the App
				Stores, but you can setup <b>MrSpeak</b> like an app.
			</div>
			<div>{viewData.screenShot}</div>
			<div className="text-xl">
				<ul className="list-decimal marker:font-bold">
					{viewData.instructions}
				</ul>
			</div>
			<div>
				<button onClick={props.onClose}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						className="w-[8vh] h-[8vh] drop-shadow"
						viewBox="0 0 16 16"
					>
						<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
					</svg>
				</button>
			</div>
		</>
	);
}

InstallAppStep.propTypes = {
	onClose: PropTypes.func,
};

export default InstallAppStep;
