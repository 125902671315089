export default class Consts {
	// Bump it anytime save data scheme was changed
	// and we need to wipe of migrate save data
	static SAVE_DATA_VERSION = 3;

	static TRACKING_ID = 'G-MRKGXJD7Y3';

	static INDEX_PAGE = 'index';

	static SEARCH_PAGE = 'search';
}
