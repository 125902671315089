export default function ReplayStep() {
	return (
		<div className="relative">
			<div className="absolute z-30 right-[8vh] top-2/4 -translate-y-full bg-gray-800 p-6 rounded drop-shadow-xl min-w-[30vh] text-xl font-normal text-gray-300">
				<div className="w-[2vh] h-[2vh] absolute bg-gray-800 right-[-1vh] bottom-[2vh] rotate-45"></div>
				Replay you recording to hear how you&apos;ve performed
			</div>
		</div>
	);
}
