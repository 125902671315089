import PropTypes from 'prop-types';

function Word(props) {
	return (
		<div className="w-[90%] flex flex-col justify-center items-center basis-0 grow-[1] text-white text-6xl drop-shadow-sm">
			<div className="flex items-center">{props.word}</div>
		</div>
	);
}

Word.propTypes = {
	word: PropTypes.string,
};

export default Word;
