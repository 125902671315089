import RecordingStep from 'components/ftue/RecordingStep';
import { FTUESteps } from 'Enums';
import PropTypes from 'prop-types';

function StartRecodingButton(props) {
	function startRecording() {
		props.onStartRecording();

		if (
			props.ftueViewModel !== null &&
			props.ftueViewModel.ftueStep === FTUESteps.RECORDING
		) {
			props.ftueViewModel.onClose();
		}
	}

	return (
		<button
			onClick={startRecording}
			className="flex justify-center items-center bg-gray-300 text-gray-700 mx-auto -mt-[10vh] w-[15vh] h-[15vh] rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-3xl"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="w-[10vh] h-[10vh] drop-shadow"
				fill="currentColor"
				viewBox="0 0 16 16"
			>
				<path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
				<path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
			</svg>

			{props.ftueViewModel !== null &&
				props.ftueViewModel.ftueStep === FTUESteps.RECORDING && (
					<RecordingStep />
				)}
		</button>
	);
}

StartRecodingButton.propTypes = {
	onStartRecording: PropTypes.func,
	ftueViewModel: PropTypes.object,
};

export default StartRecodingButton;
