import PropTypes from 'prop-types';

function ShowNextWordButton(props) {
	return (
		<button
			onClick={props.onShowNextWord}
			className="flex justify-center items-center bg-gray-300 text-gray-700 mx-auto -mt-[10vh] w-[15vh] h-[15vh] rounded-full ring-2 ring-gray-900/5 shadow-2xl font-bold text-3xl"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="w-[15vh] h-[15vh] drop-shadow"
				fill="currentColor"
				viewBox="0 0 16 16"
			>
				<path
					fillRule="evenodd"
					d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
				/>
			</svg>
		</button>
	);
}

ShowNextWordButton.propTypes = {
	onShowNextWord: PropTypes.func,
};

export default ShowNextWordButton;
