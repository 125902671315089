import Debug from 'utils/Debug';
import AssessmentService from 'services/AssessmentService';
import { ErrorFlags as ErrorCodes } from 'Enums';

export default class PronounceService {
	//------------------------------------------------------
	static async getWordAudioUrl(word) {
		try {
			const response = await fetch(
				`/api/get-word-pronounce?word=${word}`,
				{
					responseType: 'blob',
					headers: {
						'User-Agent': window.navigator.userAgent,
					},
				}
			);

			if (response.ok !== true) {
				throw new Error(response.statusText);
			}

			const blob = await response.blob();
			const result = await AssessmentService.getBlobPhonemes(word, blob);
			const audioURL = URL.createObjectURL(blob);
			return [audioURL, result.Phonemes, result.AccuracyScore];
		} catch (err) {
			Debug.error(`[PronounceService] ${err}`);
			return [
				null,
				null,
				null,
				ErrorCodes.CANT_DOWNLOAD_WORD_PRONUNCIATION,
			];
		}
	}

	//------------------------------------------------------
}
