import useAppManager from 'useAppManager';
import MainScreen from 'screens/MainScreen';
import SearchScreen from 'screens/SearchScreen';
import PopupScreen from 'screens/PopupScreen';
import FTUEScreen from 'screens/FTUEScreen';

function App() {
	const { mainViewModel, searchViewModel, popupViewModel, ftueViewModel } =
		useAppManager();

	return (
		<div className="min-h-screen bg-gray-900 flex text-white justify-center">
			<MainScreen {...mainViewModel} ftueViewModel={ftueViewModel} />
			{searchViewModel !== null && <SearchScreen {...searchViewModel} />}
			{popupViewModel !== null && <PopupScreen {...popupViewModel} />}
			{ftueViewModel !== null && <FTUEScreen {...ftueViewModel} />}
		</div>
	);
}

export default App;
