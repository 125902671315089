import PropTypes from 'prop-types';

function LevelProgress(props) {
	return (
		<div className="flex flex-row justify-center items-center basis-0 grow-[0.3] w-full">
			{Array.from(Array(props.totalSteps).keys()).map((item) => (
				<div
					key={item}
					className="w-[3vh] h-[3vh] flex justify-center items-end"
				>
					{item < props.completedSteps &&
						props.skippedWords[item] === false && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-[2vh] h-[2vh]"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
							</svg>
						)}
					{item < props.completedSteps &&
						props.skippedWords[item] === true && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-[2vh] h-[2vh]"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
							</svg>
						)}
					{item >= props.completedSteps && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-[2vh] h-[2vh]"
							fill="currentColor"
							viewBox="0 0 16 16"
						>
							<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
						</svg>
					)}
				</div>
			))}
		</div>
	);
}

LevelProgress.propTypes = {
	totalSteps: PropTypes.number,
	currentStep: PropTypes.number,
	completedSteps: PropTypes.number,
	skippedWords: PropTypes.array,
};

export default LevelProgress;
