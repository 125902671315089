function writeFloat32(output, initialOffset, input) {
	let offset = initialOffset;
	for (let i = 0; i < input.length; i += 1, offset += 4) {
		output.setFloat32(offset, input[i], true);
	}
}

function floatTo16BitPCM(output, initialOffset, input) {
	let offset = initialOffset;
	for (let i = 0; i < input.length; i += 1, offset += 2) {
		const s = Math.max(-1, Math.min(1, input[i]));
		output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
	}
}

function writeString(view, offset, string) {
	for (let i = 0; i < string.length; i += 1) {
		view.setUint8(offset + i, string.charCodeAt(i));
	}
}

function encodeWAV(samples, format, sampleRate, numChannels, bitDepth) {
	const bytesPerSample = bitDepth / 8;
	const blockAlign = numChannels * bytesPerSample;

	const buffer = new ArrayBuffer(44 + samples.length * bytesPerSample);
	const view = new DataView(buffer);

	/* RIFF identifier */
	writeString(view, 0, 'RIFF');
	/* RIFF chunk length */
	view.setUint32(4, 36 + samples.length * bytesPerSample, true);
	/* RIFF type */
	writeString(view, 8, 'WAVE');
	/* format chunk identifier */
	writeString(view, 12, 'fmt ');
	/* format chunk length */
	view.setUint32(16, 16, true);
	/* sample format (raw) */
	view.setUint16(20, format, true);
	/* channel count */
	view.setUint16(22, numChannels, true);
	/* sample rate */
	view.setUint32(24, sampleRate, true);
	/* byte rate (sample rate * block align) */
	view.setUint32(28, sampleRate * blockAlign, true);
	/* block align (channel count * bytes per sample) */
	view.setUint16(32, blockAlign, true);
	/* bits per sample */
	view.setUint16(34, bitDepth, true);
	/* data chunk identifier */
	writeString(view, 36, 'data');
	/* data chunk length */
	view.setUint32(40, samples.length * bytesPerSample, true);
	if (format === 1) {
		// Raw PCM
		floatTo16BitPCM(view, 44, samples);
	} else {
		writeFloat32(view, 44, samples);
	}

	return buffer;
}

function interleave(inputL, inputR) {
	const length = inputL.length + inputR.length;
	const result = new Float32Array(length);

	let index = 0;
	let inputIndex = 0;

	while (index < length) {
		result[(index += 1)] = inputL[inputIndex];
		result[(index += 1)] = inputR[inputIndex];
		inputIndex += 1;
	}
	return result;
}

function audioBufferToWav(buffer, opt = {}) {
	const numChannels = buffer.numberOfChannels;
	const { sampleRate } = buffer;
	const format = opt.float32 ? 3 : 1;
	const bitDepth = format === 3 ? 32 : 16;

	let result;
	if (numChannels === 2) {
		result = interleave(buffer.getChannelData(0), buffer.getChannelData(1));
	} else {
		result = buffer.getChannelData(0);
	}

	return encodeWAV(result, format, sampleRate, numChannels, bitDepth);
}

export default audioBufferToWav;
