import audioBufferToWav from 'utils/audioBufferToWav';
import 'utils/AudioContextExtension';
import stopAndReceiveBlobAsync from 'utils/stopAndReceiveBlobAsync';
import readFileAsync from 'utils/readFileAsync';

const SAMPLE_RATE = 16000;
const REC_LENGTH_IN_MILSEC_INTRO = 1500;
const REC_LENGTH_IN_MILSEC_PER_PHONEME = 200;

export default class RecordingService {
	//------------------------------------------------------
	static #instance;

	static get IsActive() {
		return (
			RecordingService.#instance !== undefined &&
			RecordingService.#instance !== null
		);
	}

	static get Instance() {
		if (!RecordingService.#instance) {
			RecordingService.#instance = new RecordingService();
		}
		return RecordingService.#instance;
	}

	//------------------------------------------------------
	async #processData(audioBlob) {
		if (audioBlob.length === 0) return [null, null];

		const result = await readFileAsync(audioBlob);

		const buffer = await this.audioContext.decodeAudioDataAsync(result);
		const wav = audioBufferToWav(buffer);

		const audioUrl = URL.createObjectURL(
			new Blob([wav], { type: 'audio/x-wav' })
		);

		return [audioUrl, wav];
	}

	//------------------------------------------------------
	async init() {
		this.recorder = null;

		const stream = await navigator.mediaDevices.getUserMedia({
			audio: true,
		});
		this.recorder = new MediaRecorder(stream, {
			audioBitsPerSecond: SAMPLE_RATE,
		});

		this.audioContext = new (window.AudioContext ||
			window.webkitAudioContext)({
			sampleRate: SAMPLE_RATE,
		});
	}

	//------------------------------------------------------
	deinit() {
		if (RecordingService.IsActive === false || this.recorder === null)
			return;

		this.audioContext = undefined;
		this.recorder.stream.getTracks().forEach((track) => {
			track.stop();
		});
		this.recorder = undefined;
		RecordingService.#instance = null;
	}

	//------------------------------------------------------
	startRecording(autoStopCallback, phonemesCount) {
		this.recorder.start();

		const maxRecLength =
			REC_LENGTH_IN_MILSEC_INTRO +
			phonemesCount * REC_LENGTH_IN_MILSEC_PER_PHONEME;
		setTimeout(autoStopCallback, maxRecLength);
	}

	//------------------------------------------------------
	async stopAndGetDataAsync() {
		const audioBlob = await stopAndReceiveBlobAsync(this.recorder);
		return this.#processData(audioBlob);
	}
}
